import {
  AI_MODE,
  AUTO_PRICE_MODE,
  BUSINESS_HOURS,
  CALCULATED,
  CALENDAR_DAY_FEE,
  CURRENT,
  DEADLINE_IGNORE_HOLIDAYS,
  DEADLINE_NOT_IGNORE_HOLIDAYS,
  DEFAULT,
  DISABLED,
  DOUBLE_POINTS,
  END_DATE,
  EVERY_24_HOURS,
  EVERY_DAY,
  FIVE_TIMES_POINTS,
  FIXED_FEE,
  MANUAL_MODE,
  MATCH,
  NO,
  NO_FEE,
  NOT_BUSINESS_HOURS,
  OFF,
  ON,
  EMAIL_NOTIFICATION_CONTENT_TYPES,
  PERIOD_SPECIFIED_FEE,
  RATE_CHECK_MODE,
  SELECTABLE,
  STANDARD,
  START_END_DATE
} from "@/config/constants"

export const getClosingOptions = i18n => [
  {
    id: MATCH,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.match")
  },
  {
    id: BUSINESS_HOURS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.hourly_consider_business_hours")
  },
  {
    id: NOT_BUSINESS_HOURS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.hourly_ignore_business_hours")
  },
  {
    id: DEADLINE_IGNORE_HOLIDAYS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.deadline_ignore_holidays")
  },
  {
    id: DEADLINE_NOT_IGNORE_HOLIDAYS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.deadline_consider_holidays")
  }
]

export const getSalesStartDateOptions = i18n => [
  {
    id: NO,
    name: i18n.t("plan_settings.basic_settings.sections.sales_start_date.no")
  },
  {
    id: BUSINESS_HOURS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.hourly_consider_business_hours")
  },
  {
    id: NOT_BUSINESS_HOURS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.hourly_ignore_business_hours")
  },
  {
    id: DEADLINE_IGNORE_HOLIDAYS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.deadline_ignore_holidays")
  },
  {
    id: DEADLINE_NOT_IGNORE_HOLIDAYS,
    name: i18n.t("plan_settings.basic_settings.sections.closing.options.deadline_consider_holidays")
  }
]

export const getReservationAvailabilityPeriodOptions = i18n => [
  {
    id: DEFAULT,
    name: i18n.t("plan_settings.basic_settings.sections.reservation_availability_period.options.business_operator")
  },
  {
    id: END_DATE,
    name: i18n.t("plan_settings.basic_settings.sections.reservation_availability_period.options.plan_end_date")
  },
  {
    id: START_END_DATE,
    name: i18n.t("plan_settings.basic_settings.sections.reservation_availability_period.options.plan_start_end_date")
  }
]

export const getCancellationPeriodOptions = i18n => [
  {
    id: DEFAULT,
    name: i18n.t("plan_settings.basic_settings.sections.cancellation_period.options.operator")
  },
  {
    id: END_DATE,
    name: i18n.t("plan_settings.basic_settings.sections.cancellation_period.options.plan_end_date")
  }
]

export const getPointsOptions = i18n => [
  {
    id: DEFAULT,
    name: i18n.t("plan_settings.basic_settings.sections.points.options.normal")
  },
  {
    id: DOUBLE_POINTS,
    name: i18n.t("plan_settings.basic_settings.sections.points.options.double")
  },
  {
    id: FIVE_TIMES_POINTS,
    name: i18n.t("plan_settings.basic_settings.sections.points.options.5_times")
  }
]

export const getFeeTypes = i18n => [
  {
    id: NO_FEE,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.no_fee_setting")
  },
  {
    id: FIXED_FEE,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.fixed_fee")
  },
  {
    id: PERIOD_SPECIFIED_FEE,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.period_specified_fee")
  },
  {
    id: CALENDAR_DAY_FEE,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.calendar_day_fee")
  },
  {
    id: EVERY_DAY,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.every_day")
  },
  {
    id: EVERY_24_HOURS,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.every_24_hours")
  }
]

export const getInsuranceFeeTypes = i18n => [
  {
    id: CALENDAR_DAY_FEE,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.calendar_day_fee")
  },
  {
    id: EVERY_DAY,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.every_day")
  },
  {
    id: EVERY_24_HOURS,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.options.every_24_hours")
  }
]

export const getSelectabilityTypes = i18n => [
  {
    id: NO,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.types.no")
  },
  {
    id: STANDARD,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.types.standard")
  },
  {
    id: SELECTABLE,
    name: i18n.t("plan_settings.basic_settings.sections.selectable_options.types.selectable")
  }
]

export const getModes = i18n => [
  {
    id: DISABLED,
    name: i18n.t("organization_management.modes.disabled")
  },
  {
    id: RATE_CHECK_MODE,
    name: i18n.t("organization_management.modes.rate_check_mode")
  },
  {
    id: MANUAL_MODE,
    name: i18n.t("organization_management.modes.manual_mode")
  },
  {
    id: AUTO_PRICE_MODE,
    name: i18n.t("organization_management.modes.auto_price_mode")
  },
  {
    id: AI_MODE,
    name: i18n.t("organization_management.modes.ai_mode")
  }
]

export const getAutoUpdatePriceOptions = (i18n, time) => () => [
  {
    label: i18n.t("general_settings.price_settings.auto_update_price.on.label"),
    text: i18n.t("general_settings.price_settings.auto_update_price.on.text", { time }),
    value: ON
  },
  {
    label: i18n.t("general_settings.price_settings.auto_update_price.off.label"),
    text: i18n.t("general_settings.price_settings.auto_update_price.off.text"),
    value: OFF
  }
]

export const getPreselectedPriceTypes = i18n => [
  {
    label: i18n.t("general_settings.price_settings.preselected_price_type.calculated_price"),
    value: CALCULATED
  },
  {
    label: i18n.t("general_settings.price_settings.preselected_price_type.current_price"),
    value: CURRENT
  }
]

export const getEmailNotificationContents = i18n => {
  return EMAIL_NOTIFICATION_CONTENT_TYPES.map(contentType => ({
    type: contentType,
    name: i18n.t(`email_notifications.contents.${contentType}`)
  }))
}

export const getModesWithFeatures = i18n => [
  {
    id: RATE_CHECK_MODE,
    features: getRateCheckModeFeatures(i18n)
  },
  {
    id: MANUAL_MODE,
    features: getManualModeFeatures(i18n)
  },
  {
    id: AUTO_PRICE_MODE,
    features: getAutoPriceModeFeatures(i18n)
  },
  {
    id: AI_MODE,
    features: getAiModeFeatures(i18n)
  }
]

export const getManualModeFeatures = i18n => [
  i18n.t("general_settings.mode_settings.features.price_calendar"),
  i18n.t("general_settings.mode_settings.features.competitors_analysis")
]

export const getAutoPriceModeFeatures = i18n => [
  i18n.t("general_settings.mode_settings.features.manual_mode_features"),
  i18n.t("general_settings.mode_settings.features.rule_settings")
]

export const getAiModeFeatures = i18n => [
  i18n.t("general_settings.mode_settings.features.manual_mode_features"),
  i18n.t("general_settings.mode_settings.features.recommended_prices")
]

export const getRateCheckModeFeatures = i18n => [i18n.t("general_settings.mode_settings.features.competitors_analysis")]
